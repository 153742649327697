import React from 'react';
import { graphql } from 'gatsby';
import { Layout, FeaturedGrid, CategoryButtons, PostGrid, Section, SearchBarInput } from 'components';
import * as styles from './styles.module.scss';

const Resources = ({ data }) => {
  const {
    prismicResourceLandingPage: { data: resourceData },
    featuredPosts: { nodes: featuredPosts = {} },
    recentPosts: { nodes: recentPosts = {} },
    categoryList: { nodes: categories = {} },
    blogSearchIndexData,
  } = data;

  const {
    meta_description: metaDesc,
    meta_title: metaTitle,
    open_graph_image: ogImage,
    resources_description: description,
    resources_title: title,
    featured_categories: featuredCategories,
    category_grid_theme: categoryGridTheme,
  } = resourceData;

  const seo = {
    title: metaTitle?.text,
    desc: metaDesc?.text,
    banner: ogImage?.url,
  };

  const categoryPosts = featuredCategories.map(({ category }) => ({
    title: category?.document?.data?.category_name?.text,
    posts: category?.recentPosts,
    categoryLink: category?.uid,
  }));

  return (
    <Layout seo={seo} theme="light">
      {/* HEADER */}
      <Section className={styles.resourceHeader} largeContainer>
        {title?.text && <div dangerouslySetInnerHTML={{ __html: title.html }} className={styles.headerTitle} />}
        {description?.text && (
          <div dangerouslySetInnerHTML={{ __html: description.html }} className={styles.headerContent} />
        )}
        {/* search bar */}
        <SearchBarInput blogPosts={blogSearchIndexData} />
      </Section>
      {/* SEARCH BUTTON */}
      <Section className={styles.topPosts} containerClassName={styles.container} largeContainer>
        <FeaturedGrid posts={featuredPosts} className={styles.featuredGrid} />
      </Section>
      {/* CATEGORIES */}
      <CategoryButtons categories={categories} categoryGridTheme={categoryGridTheme} />
      {/* RECENT */}
      <Section className={styles.recentPostsContainer} containerClassName={styles.container} largeContainer>
        <PostGrid
          title="Latest Resources"
          posts={recentPosts}
          className={styles.recentPosts}
          vertical
          showExcerpt
          readMore
        />
      </Section>
      {/* REST OF GRID */}
      {categoryPosts.map((item, index) => {
        const { title: gridTitle, posts: gridPosts, categoryLink } = item;
        if (!gridPosts || gridPosts.length < 1) return null;
        return (
          <Section className={index % 2 === 0 ? styles.categoryGrid : styles.categoryGridDark} largeContainer>
            <PostGrid
              posts={gridPosts}
              title={gridTitle}
              categoryLink={categoryLink}
              className={`blog-lp-post-grid ${styles.postGrid}`}
              darkMode={index % 2 !== 0}
            />
          </Section>
        );
      })}
    </Layout>
  );
};

export default Resources;

export const resourceQuery = graphql`
  query ResourcePage($lang: String!) {
    prismicResourceLandingPage(lang: { eq: $lang }) {
      data {
        meta_description {
          html
          text
        }
        meta_title {
          html
          text
        }
        open_graph_image {
          url
        }
        resources_description {
          html
          text
        }
        resources_title {
          html
          text
        }
        category_grid_theme
        featured_categories {
          category {
            uid
            document {
              ... on PrismicResourceCategory {
                id
                data {
                  category_name {
                    html
                    text
                  }
                }
              }
            }
            recentPosts {
              uid
              data {
                alternative_link {
                  url
                }
                author {
                  document {
                    ... on PrismicResourceAuthor {
                      data {
                        author_name {
                          text
                        }
                      }
                    }
                  }
                }
                category {
                  document {
                    ... on PrismicResourceCategory {
                      uid
                      id
                      data {
                        category_name {
                          text
                        }
                      }
                    }
                  }
                }
                date(formatString: "MMM DD YYYY")
                excerpt {
                  text
                  html
                }
                featured_image {
                  url
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 2000, placeholder: BLURRED)
                    }
                  }
                }
                is_featured
                title {
                  text
                }
                source {
                  html
                  text
                }
              }
            }
          }
        }
      }
    }
    featuredPosts: allPrismicResourcePost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: { data: { is_featured: { eq: true } }, lang: { eq: $lang } }
    ) {
      nodes {
        uid
        data {
          alternative_link {
            url
          }
          author {
            document {
              ... on PrismicResourceAuthor {
                data {
                  author_name {
                    text
                  }
                }
              }
            }
          }
          category {
            document {
              ... on PrismicResourceCategory {
                uid
                id
                data {
                  category_name {
                    text
                  }
                }
              }
            }
          }
          date(formatString: "MMM DD YYYY")
          excerpt {
            text
            html
          }
          featured_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 2000, placeholder: BLURRED)
              }
            }
          }
          is_featured
          title {
            text
          }
          source {
            html
            text
          }
        }
      }
    }
    recentPosts: allPrismicResourcePost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: { lang: { eq: $lang } }
    ) {
      nodes {
        uid
        data {
          alternative_link {
            url
          }
          author {
            document {
              ... on PrismicResourceAuthor {
                data {
                  author_name {
                    text
                  }
                }
              }
            }
          }
          category {
            document {
              ... on PrismicResourceCategory {
                uid
                id
                data {
                  category_name {
                    text
                  }
                }
              }
            }
          }
          date(formatString: "MMM DD YYYY")
          excerpt {
            text
            html
          }
          featured_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 2000, placeholder: BLURRED)
              }
            }
          }
          is_featured
          title {
            text
          }
          source {
            html
            text
          }
        }
      }
    }
    categoryList: allPrismicResourceCategory(filter: { lang: { eq: $lang } }) {
      nodes {
        data {
          category_name {
            html
            text
          }
        }
        uid
      }
    }
    blogSearchIndexData: allPrismicResourcePost(filter: { lang: { eq: $lang } }) {
      nodes {
        ...BlogSearchIndexFragment
      }
    }
  }
`;
