// extracted by mini-css-extract-plugin
export var bottomGrid = "styles-module--bottomGrid--ae89f";
export var categoryGrid = "styles-module--categoryGrid--fa899";
export var categoryGridDark = "styles-module--categoryGridDark--01c02";
export var featuredGrid = "styles-module--featuredGrid--5323c";
export var headerContent = "styles-module--headerContent--32fe6";
export var headerTitle = "styles-module--headerTitle--7aad9";
export var popularPosts = "styles-module--popularPosts--ff8b3";
export var postGrid = "styles-module--postGrid--6e167";
export var recentPosts = "styles-module--recentPosts--7dc4b";
export var recentPostsContainer = "styles-module--recentPostsContainer--06a07";
export var resourceHeader = "styles-module--resourceHeader--cd44d";
export var topPosts = "styles-module--topPosts--0e639";
export var visibleContainer = "styles-module--visibleContainer--fa954";